<template>
  <div>
    <b-row class="mt-4">
      <b-col cols="6">
        <rm-input
          class="mb-5"
          label="Training Title"
          outlined
          value="How to be a successful marketer in 6 days"
        ></rm-input>

        <rm-input
          class="mb-5"
          label="Video Link"
          outlined
          value="https://vimeo.com/realmax/training/how-to-trgis"
        >
          <template v-slot:append>
            <div
              class="border border-top-0 border-left-0 border-right-0 d-inline text-black text-nowrap pointer border-black"
            >
              View Link
            </div>
          </template>
        </rm-input>

        <rm-input
          class="mb-5"
          label="Audio Link"
          outlined
          value="https://vimeo.com/realmax/training/how-to-trgis"
          ><template v-slot:append>
            <div
              class="border border-top-0 border-left-0 border-right-0 d-inline text-black text-nowrap pointer border-black"
            >
              View Link
            </div>
          </template>
        </rm-input>

        <rm-input
          class="mb-5"
          label="Transcript"
          outlined
          value="https://vimeo.com/realmax/training/how-to-trgis"
          ><template v-slot:append>
            <div
              class="border border-top-0 border-left-0 border-right-0 d-inline text-black text-nowrap pointer border-black"
            >
              View Link
            </div>
          </template>
        </rm-input>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col cols="4">
        <div class="label font-weight-bold text-gray-800 mb-2">
          Thumbnail Preview
        </div>
        <div class="w-75">
          <rm-training-card :training="training" />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="6">
        <div class="text-subheader text-black">Create Quiz</div>
        <div class="text-sm-2 text-black">No questions have been added!</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RmTrainingCard from "./components/RmTrainingCard.vue";

export default {
  components: {
    RmTrainingCard,
  },
  data: () => ({
    training: {
      banner:
        "https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8",
    },
  }),
};
</script>
